import React from "react";

const Button = ({ label, icon, type = "button", onClick, className = '', size = 'normal', variant = 'primary' }) => {
    const baseStyles =
        "flex items-center justify-center rounded-xl shadow-md transition-all duration-300";

    // Styles for normal and small buttons
    const sizeStyles =
        size === 'small'
            ? "px-3 py-1 text-sm" // Smaller padding and font size
            : "px-6 py-2 text-base";

    // Default background styles
    const bgStyles = "bg-brand-500 dark:bg-brand-400 text-white hover:bg-brand-600 hover:dark:bg-brand-600";

    // Styles for 'secondary' (cancel) buttons
    const cancelStyles =
        "bg-red-500 dark:bg-red-500 text-white dark:text-white hover:bg-red-700 hover:dark:bg-red-700";

    // Apply 'cancelStyles' if variant is 'secondary', otherwise apply default 'bgStyles'
    const buttonStyles = variant === 'secondary' ? cancelStyles : bgStyles;

    return (
        <div className="flex justify-center">
            <button
                onClick={onClick}
                type={type}
                className={`${baseStyles} ${sizeStyles} ${buttonStyles} ${className}`}
            >
                {icon && <span className="mr-2">{icon}</span>}
                {label}
            </button>
        </div>
    );
};

export default Button;
