import React, { useState, useEffect, useMemo } from "react";
import { formatDateToDDMM, sortRulesByDate } from "./helper.js";
import Modal from "components/Modal/Modal";
import InputField from "components/fields/InputField";
import Button from "components/button/Button";
import apiClient from "../axiosInstance";
import { XMarkIcon } from "@heroicons/react/20/solid";
import CustomCalendar from 'components/calendar/CustomCalendar';
import { formatDateWithoutYear } from './helper.js';

const DiscountTableForCar = ({ car, updatePriceRulesForCar }) => {
    const [priceRules, setPriceRules] = useState([]);
    const [showNewPriceModal, setShowNewPriceModal] = useState(false);
    const [showEditPriceModal, setShowEditPriceModal] = useState(false);
    const [showNewRuleModal, setShowNewRuleModal] = useState(false);
    const [selectedRange, setSelectedRange] = useState(null);
    const [FromDate, setFromDate] = useState("");
    const [ToDate, setToDate] = useState("");
    const [selectedMinDays, setSelectedMinDays] = useState(null);
    const [newPrice, setNewPrice] = useState("");
    const [selectedRuleId, setSelectedRuleId] = useState(null);
    const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState(false);
    const [selectedRowToDelete, setSelectedRowToDelete] = useState(null);

    useEffect(() => {
        if (car?.priceRules) {
            setPriceRules(car.priceRules);
        }
    }, [car]);

    useEffect(() => {
        if (selectedRange) {
            const { StartDate, EndDate } = parseRangeToDates(selectedRange);
            setFromDate(StartDate);
            setToDate(EndDate);
        }
    }, [selectedRange]);


    const parseRangeToDates = (rangeKey) => {
        const [start, end] = rangeKey.split(" - ");
        const [startDay, startMonth] = start.split("/");
        const [endDay, endMonth] = end.split("/");

        return {
            StartDate: `2000-${startMonth}-${startDay}`,
            EndDate: `2000-${endMonth}-${endDay}`,
        };
    };
    const formatDateWithoutTime = (date) => {
        if (!date) return "";
        const year = date.getFullYear();
        const month = ("0" + (date.getMonth() + 1)).slice(-2);
        const day = ("0" + date.getDate()).slice(-2);
        return `${year}-${month}-${day}`;
    };

    const handleSaveNewPrice = async () => {
        if (!selectedMinDays || !newPrice) {
            alert("Please fill out all fields.");
            return;
        }

        let StartDate, EndDate;
        if (showNewPriceModal) {
            ({ StartDate, EndDate } = parseRangeToDates(selectedRange));
        } else if (showNewRuleModal) {
            StartDate = formatDateWithoutTime(FromDate);
            EndDate = formatDateWithoutTime(ToDate);
        }

        // Convert selectedMinDays to a number
        const minDaysNumber = Number(selectedMinDays);

        // Validate for existing rule overlap with same minDays
        const isOverlap = priceRules.some((rule) => {
            const existingStartDate = new Date(rule.RuleStartDate);
            const existingEndDate = new Date(rule.RuleEndDate);
            const newStartDate = new Date(StartDate);
            const newEndDate = new Date(EndDate);

            return (
                newStartDate <= existingEndDate &&
                newEndDate >= existingStartDate &&
                rule.MinDays === minDaysNumber
            );
        });

        if (isOverlap) {
            alert("There is already a rule with the same Discount days within this date range.");
            return;
        }

        try {
            const response = await apiClient.post(`/insert-rule/${car.CarID}`, {
                StartDate,
                EndDate,
                MinDays: minDaysNumber,
                NewPricePerDay: newPrice,
            });

            if (response.status === 200) {
                const newPriceRule = {
                    CarPriceRuleID: response.data.CarPriceRuleID,
                    RuleStartDate: StartDate,
                    RuleEndDate: EndDate,
                    MinDays: minDaysNumber,
                    NewPricePerDay: newPrice,
                };

                updatePriceRulesForCar(car.CarID, newPriceRule, null);
            }

            setShowNewPriceModal(false);
            setShowNewRuleModal(false);
            setFromDate("");
            setToDate("");
            setNewPrice("");
            setSelectedRange(null);
            setSelectedMinDays(null);
        } catch (error) {
            alert("Failed to add the new discount rule. Please try again.");
        }
    };



    const handleEditPrice = async () => {
        if (!newPrice || !selectedRuleId) {
            alert("Please provide a new price and select a valid rule to update.");
            return;
        }

        try {
            const response = await apiClient.put(`/update-rule/${selectedRuleId}`, { NewPricePerDay: newPrice, });

            if (response.status === 200) {
                setPriceRules((prevRules) =>
                    prevRules.map((rule) =>
                        rule.CarPriceRuleID === selectedRuleId
                            ? { ...rule, NewPricePerDay: newPrice }
                            : rule
                    )
                );
                setFromDate("");
                setToDate("");
                setShowEditPriceModal(false);
                setNewPrice("");
                setSelectedRuleId(null);
            }
        } catch (error) {
            alert("Failed to update the price rule. Please try again.");
        }
    };

    const sortedRules = useMemo(() =>
        sortRulesByDate(
            priceRules.map((rule) => ({
                CarPriceRuleID: rule.CarPriceRuleID,
                RuleStartDate: rule.RuleStartDate,
                RuleEndDate: rule.RuleEndDate,
                MinDays: rule.MinDays,
                NewPricePerDay: rule.NewPricePerDay,
            }))
        ), [priceRules]
    );

    const groupedDateRanges = useMemo(() => {
        const groups = {};
        sortedRules.forEach((rule) => {
            const key = `${formatDateToDDMM(rule.RuleStartDate)} - ${formatDateToDDMM(rule.RuleEndDate)}`;
            if (!groups[key]) {
                groups[key] = {};
            }
            groups[key][rule.MinDays] = rule;
        });
        return groups;
    }, [sortedRules]);

    const minDaysArray = Array.from(
        new Set(sortedRules.map((r) => r.MinDays))
    ).sort((a, b) => a - b);

    const handleRemovePriceRow = async (CarID, rangeKey) => {
        try {
            const CarPriceRuleIDs = Object.values(groupedDateRanges[rangeKey]).map(
                (rule) => rule.CarPriceRuleID
            );

            const response = await apiClient.put(`/delete-rules/${CarID}`, { CarPriceRuleIDs });

            if (response.status === 200) {

                setPriceRules((prevRules) => {
                    return prevRules.filter(
                        (rule) => !CarPriceRuleIDs.includes(rule.CarPriceRuleID)
                    );
                });

                updatePriceRulesForCar(CarID, null, CarPriceRuleIDs);
            }
        } catch (error) {
            alert("Failed to remove the price rule. Please try again.");
        }
    };

    const handleRemoveSinglePriceRow = async (CarID, CarPriceRuleID) => {
        try {
            const response = await apiClient.put(`/delete-rule/${CarID}`, {
                CarPriceRuleID: CarPriceRuleID,
            });

            if (response.status === 200) {
                updatePriceRulesForCar(CarID, null, [CarPriceRuleID]);
                setNewPrice("");
                setShowEditPriceModal(false);
            }
        } catch (error) {
            alert("Failed to remove the price rule. Please try again.");
        }
    };

    const openRowDeleteConfirmationModal = (rangeKey) => {
        setSelectedRowToDelete(rangeKey);
        setShowDeleteConfirmationModal(true);
    };

    const closeDeleteConfirmationModal = () => {
        setShowDeleteConfirmationModal(false);
    };

    return (
        <div>
            {priceRules.length > 0 ? (
                <div className="overflow-x-auto mt-2">
                    <table className="w-full table-auto border-collapse">
                        <thead>
                            <tr className="border-b border-gray-300">
                                <th className="text-left px-2 py-1">Dates</th>
                                {minDaysArray.map((minDays) => (
                                    <th key={minDays} className="text-center px-2 py-1">
                                        {minDays}+
                                    </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {Object.keys(groupedDateRanges).map((rangeKey) => (
                                <tr key={rangeKey} className="border-t border-gray-300">
                                    <td className="px-2 py-1">{rangeKey}</td>
                                    {minDaysArray.map((minDays) => {
                                        const existingPrice =
                                            groupedDateRanges[rangeKey][minDays] !== undefined
                                                ? groupedDateRanges[rangeKey][minDays]
                                                : null;

                                        // Check if minDays exists anywhere in the same date range with a valid price
                                        const isMinDaysInRangeWithPrice = Object.values(groupedDateRanges[rangeKey]).some(
                                            (rule) => rule.MinDays === minDays && rule.NewPricePerDay
                                        );

                                        return (
                                            <td key={minDays} className="text-center px-2 py-1">
                                                {existingPrice ? (
                                                    <span
                                                        className="cursor-pointer hover:text-blue-600"
                                                        onClick={() => {
                                                            const selectedRule = groupedDateRanges[rangeKey][minDays];
                                                            setSelectedRuleId(selectedRule.CarPriceRuleID);
                                                            setNewPrice(selectedRule.NewPricePerDay);
                                                            setSelectedRange(rangeKey);
                                                            setSelectedMinDays(minDays);
                                                            setShowEditPriceModal(true);
                                                        }}
                                                    >
                                                        {existingPrice.NewPricePerDay}
                                                    </span>
                                                ) : isMinDaysInRangeWithPrice ? (
                                                    <span className="text-gray-500 font-semibold">X</span> // Non-clickable "X"
                                                ) : (
                                                    <span
                                                        className="cursor-pointer hover:text-blue-600"
                                                        onClick={() => {
                                                            setSelectedRange(rangeKey);
                                                            setSelectedMinDays(minDays);
                                                            setShowNewPriceModal(true);
                                                        }}
                                                    >
                                                        -
                                                    </span>
                                                )}
                                            </td>
                                        );
                                    })}
                                    <td>
                                        <button
                                            onClick={() => openRowDeleteConfirmationModal(rangeKey)}
                                            className="text-red-500 hover:text-red-700 transition-all"
                                        >
                                            <XMarkIcon className="h-6 w-6" />
                                        </button>
                                    </td>
                                </tr>
                            ))}

                        </tbody>
                    </table>
                    <Button
                        onClick={() => {
                            setSelectedRange("");
                            setSelectedMinDays("");
                            setNewPrice("");
                            setShowNewRuleModal(true);
                        }}
                        className="mt-4"
                        label="Add New Rule"
                    />
                </div>
            ) : (
                <div className="p-4">
                    <p className="text-sm text-red-500">No discount rules for this car.</p>
                    <Button
                        onClick={() => {
                            setSelectedRange("");
                            setSelectedMinDays("");
                            setNewPrice("");
                            setFromDate("");
                            setToDate("");
                            setShowNewRuleModal(true);
                               
                        }}
                        className="mt-2"
                        label="Add New Rule"
                    />
                </div>
            )}

            <Modal
                isOpen={showNewRuleModal}
                onClose={() => { setShowNewRuleModal(false); }}
                title="Add New Discount Rule"
                content={
                    <div className="mt-4 flex flex-wrap gap-4 items-center">
                        <div className="flex flex-col w-full sm:w-1/2 lg:w-1/4">
                            <label htmlFor="fromDate" className="text-sm font-medium text-gray-700">
                                From Date
                            </label>
                            <CustomCalendar
                                name="fromDate"
                                value={formatDateWithoutYear(FromDate)}
                                onChange={(date) => setFromDate(date)}
                            />
                        </div>
                        <div className="flex flex-col w-full sm:w-1/2 lg:w-1/4">
                            <label htmlFor="toDate" className="text-sm font-medium text-gray-700">
                                To Date
                            </label>
                            <CustomCalendar
                                name="toDate"
                                value={formatDateWithoutYear(ToDate)}
                                minDate={FromDate}
                                disabled={!FromDate}
                                onChange={(date) => setToDate(date)}
                            />
                        </div>

                        <div className="flex flex-col w-full sm:w-1/2 lg:w-1/4">
                            <label htmlFor="minDays" className="text-sm font-medium text-gray-700">
                                Min Days
                            </label>
                            <InputField
                                id="minDays"
                                type="number"
                                value={selectedMinDays}
                                onChange={(e) => setSelectedMinDays(e.target.value)}
                            />
                        </div>
                        <div className="flex flex-col w-full sm:w-1/2 lg:w-1/4">
                            <label htmlFor="pricePerDay" className="text-sm font-medium text-gray-700">
                                Price/Day
                            </label>
                            <InputField
                                id="pricePerDay"
                                type="number"
                                value={newPrice}
                                onChange={(e) => setNewPrice(e.target.value)}
                            />
                        </div>
                    </div>

                }
                footer={
                    <div className="flex mt-4 space-x-2 justify-end">
                        <Button label="Save" onClick={handleSaveNewPrice} />
                    </div>
                }
            />
            {/* Delete Confirmation Modal */}
            <Modal
                isOpen={showDeleteConfirmationModal}
                onClose={closeDeleteConfirmationModal}
                title="Confirm Deletion"
                content={
                    <div>
                        <p>Are you sure you want to delete this entire date range?</p>
                    </div>
                }
                footer={
                    <div className="flex mt-4 space-x-2 justify-start">
                        <Button
                            label="Delete"
                            variant="secondary"
                            onClick={async () => {
                                await handleRemovePriceRow(car.CarID, selectedRowToDelete);
                                closeDeleteConfirmationModal();
                            }}
                        />
                    </div>
                }
            />
            {/* New Price Modal */}
            <Modal
                isOpen={showNewPriceModal}
                onClose={() => {
                    setShowNewPriceModal(false);
                    setNewPrice("");
                }}
                title="Add New Discount Rule"
                content={
                    <div>
                        <p><strong>Date Range:</strong> {selectedRange}</p>
                        <p><strong>Min Days:</strong> {selectedMinDays}</p>
                        <div className="mt-4">
                            <label className="block mb-2">New Price Per Day:</label>
                            <InputField
                                type="number"
                                value={newPrice}
                                placeholder="Enter a discount price"
                                onChange={(e) => setNewPrice(e.target.value)}
                            />
                        </div>
                    </div>
                }
                footer={
                    <div className="flex mt-4 space-x-2 justify-end">
                        <Button
                            label="Save"
                            onClick={handleSaveNewPrice} />
                    </div>
                }
            />
            {/* Edit Price Modal */}
            <Modal
                isOpen={showEditPriceModal}
                onClose={() => {
                    setShowEditPriceModal(false);
                    setNewPrice("");
                }}
                title="Edit Discount Rule"
                content={
                    <div>
                        <p><strong>Date Range:</strong> {selectedRange}</p>
                        <p><strong>Min Days:</strong> {selectedMinDays}</p>
                        <div className="mt-4">
                            <label className="block mb-2">New Price Per Day:</label>
                            <InputField
                                type="number"
                                value={newPrice}
                                placeholder="Enter new discount price"
                                onChange={(e) => setNewPrice(e.target.value)}
                            />
                        </div>
                    </div>
                }
                footer={
                    <div className="flex mt-4 space-x-2 justify-between">
                        <Button
                            className="mr-auto"
                            label="Delete"
                            variant="secondary"
                            onClick={() => handleRemoveSinglePriceRow(car.CarID, selectedRuleId)}
                        />

                        <Button label="Save" onClick={handleEditPrice} />
                    </div>

                }
            />
        </div>
    );

}    

export default DiscountTableForCar;
