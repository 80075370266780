import React, { useEffect, useState } from "react";
import Card from "components/card";
import InputField from "components/fields/InputField";
import apiClient from "../axiosInstance";
import Modal from "components/Modal/Modal";
import { CheckCircleIcon, XMarkIcon } from "@heroicons/react/20/solid";
import Button from 'components/button/Button';
import {
    formatDateWithoutYear,
    handleRemovePriceRow,
    handlePriceChange,
    handleDateChangeLogic,
    showModal,
} from './helper.js';
import CustomCalendar from 'components/calendar/CustomCalendar';
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/solid";
import DiscountTableForCar from "./DiscountTableForCar";

const PricesOverview = () => {
    const [cars, setCars] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalContent, setModalContent] = useState({});
    const [expandedCars, setExpandedCars] = useState({});

    const toggleCarRules = (carId) => {
        setExpandedCars((prev) => ({
            ...prev,
            [carId]: !prev[carId],
        }));
    };

    useEffect(() => {
        const fetchCarsAndPrices = async () => {
            try {
                const response = await apiClient.get("/cars-and-prices");
                let carsData = response.data;

                carsData = carsData.map((car) => ({
                    ...car,
                    prices: car.prices.length > 0
                        ? car.prices.map((priceRow) => ({
                            ...priceRow,
                            StartDate: priceRow.StartDate ? priceRow.StartDate : "2000-01-01T00:00:00.000Z",
                            EndDate: priceRow.EndDate ? priceRow.EndDate : "2000-12-31T00:00:00.000Z",
                        }))
                        : [{
                            StartDate: "2000-01-01T00:00:00.000Z",
                            EndDate: "2000-12-31T00:00:00.000Z",
                            PricePerDay: '',
                        }],
                }));

                setCars(carsData);

            } catch (err) {
                console.log(err.message);
            }
        };

        fetchCarsAndPrices();
    }, []);

    const handlesave = async (selectedCarID, cars) => {
        let selectedCar = cars.find((car) => car.CarID === selectedCarID);

        let priceUpdates = selectedCar.prices.map((price) => ({
            StartDate: new Date(price.StartDate).toISOString().split("T")[0],
            EndDate: new Date(price.EndDate).toISOString().split("T")[0],
            PricePerDay: price.PricePerDay,
        }));

        try {
            const response = await apiClient.post(`/update-prices/${selectedCarID}`, {
                updates: priceUpdates,
            });

            if (response.status === 200) {
                showModal(
                    setModalContent,
                    setIsModalOpen,
                    "Success",
                    <div className="flex flex-col items-center">
                        <CheckCircleIcon className="h-12 w-12 text-green-500 mb-4" />
                        <p>Prices updated successfully.</p>
                    </div>
                );
            } else {
                showModal(
                    setModalContent,
                    setIsModalOpen,
                    "Error",
                    <div className="flex flex-col items-center">
                        <XMarkIcon className="h-12 w-12 text-red-500 mb-4" />
                        <p>Failed to update prices. Please try again.</p>
                    </div>
                );
            }
        } catch (error) {
            showModal(
                setModalContent,
                setIsModalOpen,
                "Error",
                <div className="flex flex-col items-center">
                    <XMarkIcon className="h-12 w-12 text-red-500 mb-4" />
                    <p>Failed to update prices. Please try again.</p>
                </div>
            );
        }
    };


    const handleDateChange = (carId, index, newEndDate) => {
        if (!newEndDate || isNaN(newEndDate.getTime())) return;
        setCars((prevCars) => handleDateChangeLogic(carId, index, newEndDate, prevCars));
    };


    const updatePriceRulesForCar = (carId, newPriceRule, CarPriceRuleIDs) => {
        setCars((prevCars) =>
            prevCars.map((car) =>
                car.CarID === carId
                    ? {
                        ...car,
                        priceRules: CarPriceRuleIDs
                            ? car.priceRules.filter((rule) => !CarPriceRuleIDs.includes(rule.CarPriceRuleID))
                            : newPriceRule
                                ? [...car.priceRules, newPriceRule]
                                : car.priceRules,
                    }
                    : car
            )
        );
    };




    return (
        <div className="flex w-full flex-col gap-5">
            <div className="mt-5 grid grid-cols-1 gap-5">
                {cars.map((car) => {
                    return (
                        <Card key={car.CarID} extra="p-5 mb-1 flex h-auto flex-col md:flex-row">
                            <div className="flex flex-col w-full">

                                {/* Top Section: Car Info/Image (Now takes 1/3 width) */}
                                <div className="w-full md:w-1/3 flex flex-col items-center text-center mb-4">
                                    <span className="block font-bold">{car.Make} {car.Model}</span>
                                    <span>{car.Transmission} {car.FuelType}</span>
                                    <img
                                        src={car.imageURL}
                                        alt={`${car.Make} ${car.Model}`}
                                        className="w-3/4 h-auto mt-2"
                                        style={{ objectFit: "contain" }}
                                    />
                                </div>

                                {/* Middle Section: Base Prices (Takes up 2/3 width) */}
                                <div className="flex w-full md:w-2/3 flex-col">
                                    {car.prices.length > 0 && (
                                        <table className="w-full table-auto">
                                            <thead>
                                                <tr>
                                                    <th className="text-left">From</th>
                                                    <th className="text-left">Until</th>
                                                    <th className="text-left">Price</th>
                                                    <th className="text-left"></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {car.prices.map((priceRow, index) => {
                                                    const { StartDate, EndDate, PricePerDay } = priceRow;

                                                    return (
                                                        <tr key={`row-${index}-${StartDate}`}>
                                                            <td className="p-1">
                                                                <CustomCalendar
                                                                    extra="gray-100"
                                                                    value={formatDateWithoutYear(StartDate)}
                                                                    disabled={true}
                                                                />
                                                            </td>
                                                            <td className="p-1">
                                                                <CustomCalendar
                                                                    minDate={new Date(StartDate)}
                                                                    maxDate={new Date(EndDate) >= new Date(StartDate)
                                                                        ? new Date(EndDate)
                                                                        : new Date("2000-12-31")}
                                                                    value={formatDateWithoutYear(EndDate)}
                                                                    onChange={(date) => handleDateChange(car.CarID, index, date)}
                                                                />
                                                            </td>
                                                            <td className="p-1">
                                                                <InputField
                                                                    extra="w-full"
                                                                    type="number"
                                                                    step="0.01"
                                                                    value={PricePerDay ?? 0}
                                                                    onChange={(e) =>
                                                                        handlePriceChange(car.CarID, index, e.target.value, setCars)}
                                                                />
                                                            </td>
                                                            <td className="w-3">
                                                                {index !== 0 && (
                                                                    <button
                                                                        onClick={() =>
                                                                            handleRemovePriceRow(car.CarID, index, setCars)}
                                                                        className="text-red-500 hover:text-red-700 transition-all mt-4"
                                                                    >
                                                                        <XMarkIcon className="h-6 w-6" />
                                                                    </button>
                                                                )}
                                                            </td>
                                                        </tr>
                                                    );
                                                })}
                                            </tbody>
                                        </table>
                                    )}

                                    {car.prices.length > 0 && (
                                        <div className="flex justify-between items-center w-full mt-4">
                                            <Button
                                                label="Save Prices"
                                                className="bg-brand-500 text-white px-4 py-2 rounded-lg
                        hover:bg-brand-600 shadow-lg hover:shadow-xl transition-all
                        duration-300 ease-in-out focus:outline-none
                        focus:ring-2 focus:ring-blue-400 focus:ring-opacity-75"
                                                onClick={() => handlesave(car.CarID, cars)}
                                            />
                                        </div>
                                    )}
                                </div>
                            </div>

                            {/* Right Side: Discounts/Rules Table (Takes 1/3 width) */}
                            <div className="w-full md:w-3/4 h-full mt-4 md:mt-0 md:ml-4">
                                <div
                                    onClick={() => toggleCarRules(car.CarID)}
                                    className="cursor-pointer flex items-center justify-between bg-gray-200 dark:bg-navy-700 p-4 rounded-lg"
                                >
                                    <h3 className="text-lg font-semibold">
                                        {car.Make} {car.Model} Discounts
                                    </h3>
                                    {expandedCars[car.CarID] ? (
                                        <ChevronUpIcon className="w-6 h-6 text-gray-600" />
                                    ) : (
                                        <ChevronDownIcon className="w-6 h-6 text-gray-600" />
                                    )}
                                </div>

                                {expandedCars[car.CarID] &&
                                    <DiscountTableForCar
                                        car={car}
                                        updatePriceRulesForCar={updatePriceRulesForCar}
                                    />
                                }
                                
                            </div>

                        </Card>

                    );
                })}
            </div>

            <Modal
                isOpen={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                title={modalContent.title}
                content={modalContent.content}
                footer={modalContent.footer}
            />
        </div>
    );
};

export default PricesOverview;
